import React, { useState } from "react";
import Layout from "../layout";
import styled from "styled-components";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import {
  navyBlue,
  mainRed,
  aku,
  innerWidth,
  transHover,
  gotham,
  screen,
} from "../components/common/variables";
import VideoModal from "../components/video-modal";
import ReactPlayer from "react-player";
import Arrow from "../images/svg/right-arrow.svg";
import { getVimeoId } from "../components/common/functions";
import logo from "../images/tenalphas-moving-content.jpg";

const Wrapper = styled.div`
  padding-top: 124px;
  padding-bottom: 90px;
  max-width: ${innerWidth};
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;
  @media ${screen.small} {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 135px;
  }
  @media ${screen.medium} {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 150px;
  }
  @media ${screen.large} {
    padding-top: 200px;
  }
  @media ${screen.xLarge} {
    padding-left: 0;
    padding-right: 0;
  }

  .heading {
    color: ${navyBlue};
    font-family: ${aku};
    font-size: 2.1rem;
    @media ${screen.small} {
      font-size: 2.7rem;
    }

    svg {
      width: 14px;
      margin-right: 8px;
      vertical-align: middle;
      @media ${screen.small} {
        width: 19px;
        margin-right: 14px;
      }
      @media ${screen.medium} {
        width: 24px;
      }

      .body {
        fill: ${mainRed};
        transition: ${transHover};
      }
    }
  }

  .description {
    margin-top: 30px;
    max-width: 1000px;
    color: ${navyBlue};
    font-family: ${gotham};
    font-size: 1rem;
    @media ${screen.small} {
      font-size: 1.1rem;
    }
  }

  .awards-container {
    margin-top: 45px;
    &__award-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      @media ${screen.small} {
        margin-left: -20px;
        margin-right: -20px;
      }
      @media ${screen.large} {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
      }
      .award {
        margin-bottom: 45px;
        display: flex;
        flex-direction: column;
        width: 100%;
        @media ${screen.small} {
          margin-bottom: 60px;
          margin-left: 20px;
          margin-right: 20px;
          width: calc(50% - 40px);
        }
        @media ${screen.medium} {
          margin-bottom: 90px;
        }
        @media ${screen.large} {
          flex-direction: row;
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }

        &__img {
          cursor: pointer;

          .thumbnail {
            max-width: 100% !important;
            width: 100%;
            position: relative;
            @media ${screen.medium} {
              max-width: 470px !important;
            }
            @media ${screen.large} {
              max-width: 470px !important;
            }
            @media ${screen.xLarge} {
              max-width: 500px !important;
            }
            @media ${screen.withCursor} {
              &:hover ::before {
                opacity: 1;
              }
            }

            .react-player__preview {
              background-size: cover !important;
              max-width: 100% !important;
              width: 100%;
              height: 250px !important;
              @media ${screen.small} {
                height: 320px !important;
              }
              @media ${screen.medium} {
                max-width: 470px !important;
                height: 300px !important;
              }
              @media ${screen.large} {
                max-width: 470px !important;
                height: 300px !important;
              }
              @media ${screen.xLarge} {
                max-width: 500px !important;
                height: 300px !important;
              }
            }

            .react-player__play-icon,
            .react-player__shadow {
              display: none !important;
            }

            &::before {
              content: " ";
              position: absolute;
              background: rgba(200, 29, 41, 0.9);
              width: 100%;
              height: 250px;
              opacity: 0;
              transition: ${transHover};
              @media ${screen.small} {
                height: 320px;
              }
              @media ${screen.medium} {
                height: 300px;
              }
            }
          }
        }

        &__texts-container {
          color: ${navyBlue};
          margin-top: -100px;
          @media ${screen.small} {
            margin-top: -20px;
          }
          @media ${screen.medium} {
            margin-left: 45px;
            margin-top: 0;
          }
          @media ${screen.large} {
            margin-left: 45px;
          }

          .title {
            font-family: ${aku};
            font-size: 1.9rem;
            font-weight: 400;
            margin-top: 10px;
            @media ${screen.small} {
              font-size: 2.1rem;
              margin-top: 15px;
            }
            @media ${screen.large} {
              margin-top: 0;
            }
          }

          .event-list {
            margin-top: 10px;
            @media ${screen.small} {
              margin-top: 20px;
            }
            @media ${screen.large} {
              margin-top: 30px;
            }

            .competition {
              font-size: 0.95rem;
              font-family: ${gotham};
              margin: 10px 0 10px 12px;
              display: flex;
              align-items: flex-start;
              line-height: 1.25;
              @media ${screen.small} {
                font-size: 1.1rem;
                margin: 10px 0 10px 20px;
              }
              @media ${screen.large} {
                margin: 10px 0 10px 35px;
              }

              svg {
                width: 7px;
                min-width: 7px;
                margin-right: 5px;
                margin-top: 4px;
                @media ${screen.small} {
                  margin-right: 10px;
                  margin-top: 6px;
                }
                @media ${screen.large} {
                  margin-right: 10px;
                  margin-top: 5px;
                }
                .body {
                  fill: ${navyBlue};
                }
              }
            }
          }
        }
      }
    }
  }
`;

const AwardsPage = ({ data }) => {
  // basic hack to trigger the video modal component to watch location hash changes
  const [triggerLightboxComponent, setTriggerLightboxComponent] = useState(
    false
  );

  // Prismic content
  const pageTitle = data.content.data.page
    ? data.content.data.page.text
    : `Awards`;
  const titleTag = data.content.data.title_tag
    ? data.content.data.title_tag
    : `Awards`;
  const metaDescription = data.content.data.meta_description
    ? data.content.data.meta_description
    : `Awards`;
  const description = data.content.data.description;

  // sort awards by year
  const sortedAwards = data.content.data.award_list
    .map((item) => {
      return {
        title: item.title,
        video: item.project.document,
        date: item.date && Number(item.date.slice(0, 4)),
        competitions: item.competitions,
      };
    })
    .sort((a, b) => b.date - a.date);

  // open video modal
  const viewVideo = (videoId) => {
    window.history.replaceState(null, null, `#${videoId}`);
    setTriggerLightboxComponent(!triggerLightboxComponent);
  };

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} image={logo} />
      <Wrapper>
        <h2 className="heading">
          <Arrow />
          {pageTitle}
        </h2>
        <p className="description">{description}</p>

        <div className="awards-container">
          <ul className="awards-container__award-list">
            {sortedAwards.map((award, i) => (
              <li className="award" key={i}>
                <div
                  className="award__img"
                  onClick={() =>
                    viewVideo(getVimeoId(award.video.data.video_url))
                  }
                >
                  <ReactPlayer
                    className="thumbnail"
                    url={award.video.data.video_url}
                    light={`https://vumbnail.com/${getVimeoId(
                      award.video.data.video_url
                    )}.jpg`}
                  />
                </div>
                <div className="award__texts-container">
                  <h3 className="title">{award.title}</h3>

                  {award.competitions.raw.length && (
                    <ul className="event-list">
                      {award.competitions.raw.map((competition, i) => (
                        <li className="competition" key={i}>
                          <Arrow /> <span>{competition.text}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <VideoModal triggerLightboxComponent={triggerLightboxComponent} />
      </Wrapper>
    </Layout>
  );
};

export default AwardsPage;

export const dataQuery = graphql`
  {
    content: prismicAwardsPage {
      data {
        description
        title_tag
        meta_description
        page {
          text
        }
        award_list {
          title
          date
          competitions {
            raw
          }
          project {
            document {
              ... on PrismicProject {
                data {
                  date
                  video_url
                  credits {
                    description
                  }
                }
              }
            }
          }
        }
      }
    }
    details: prismicGeneralDetails {
      data {
        email_address
      }
    }
  }
`;
